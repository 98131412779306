import React from 'react'
import './main.css'
import './home.css'
import { useState, useEffect } from 'react'
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faX, faTimes, faEnvelope, faPhone, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../LoghiTGM/ScrittaBianca.png';
import sun from './ImageTheme/sun.png';
import moon from './ImageTheme/moon.png';
import prova1 from '../LoghiTGM/prova.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import studio1 from '../Images/Studio2.jpeg';
import studio2 from '../Images/StudioModerno.jpeg';
import studio3 from '../Images/Studio.jpg';


function Main() {

    const [toBottom, setToBottom] = useState(false)
    const [nav, setNav] = useState(false)

    //per avviare la conversazione
    function activateBot(event) {
        setNav(true);
        event.preventDefault();
        setToBottom(true);
        
        const date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    
        const str_time = hour + ":" + minute;
        var rawText = $("#text").val();
    
        var userHtml = '<div class="d-flex justify-content-end mb-4"><div class="' + (invertTheme ? "msg_cotainer_send" : "msg_cotainer_send dark") + '">' + rawText + '<span class="msg_time_send">' + str_time + '</span></div><div class="img_cont_msg"><div class="' + (invertTheme ? "rounded-circle user_img_msg" : "rounded-circle user_img_msg dark") + '" style="display:flex; align-items:center; justify-content:center;"><i class="fas fa-user"></i></div></div></div>';
    
        $("#text").val("");
        $("#messageFormeight").append(userHtml);
    
        async function startSession() {
            let uuid = '2a17f35a34fd4640a9f2af0538dc5ce3';
            let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxODk3NDE4NiwianRpIjoiMTZmNDI1ZTEtZjUxMS00NDhhLTk2NjctMDRhZDU0MDIxOWJmIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJhcGlfa2V5IjoiM2Y4OTRhZDU2MTBhZDYzNjcyOWE2NTU3OTIwMTg4MmIxZTE4ZDg1ZjIxNTQ2MzI2NWVhYzIxOTA5ZTA3ODRjMCJ9LCJuYmYiOjE3MTg5NzQxODZ9.3JpAXLYDOwv8jwVnOPan2tWqXRyqaE-MdFPeeEXhctg';
            let url = `https://app.gpt-trainer.com/api/v1/chatbot/${uuid}/session/create`;
            let risposta = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!risposta.ok) {
                throw new Error("Errore di rete", risposta.status);
            }
            return await risposta.json();
        }
    
        async function sendMessage(message, uuid) {
            let query = {
                'query': message
            };
            let url = `https://app.gpt-trainer.com/api/v1/session/${uuid}/message/stream`;
            let risposta = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxNDQ3MDA4NSwianRpIjoiMzg4ZWI4OTMtMmU1Ni00NDY1LWE4ZmYtOTZlMzFmMjc0ZDVkIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJhcGlfa2V5IjoiZmNiOGM4ZjE1NWE5NTEzMDdkNWE1YTFjN2NmMjljMTY2MzdhODRkMzA1NzA0Y2E4YTI1MTUyZGFiMTVhYjNiOCJ9LCJuYmYiOjE3MTQ0NzAwODV9.gaDO2Mdii32YZ_WXcouEJfkyIptpOJXXISs-2h3hdhE`
                },
                body: JSON.stringify(query)
            });
            if (!risposta.ok) {
                console.log("errore");
            }

            return await risposta.text();
        }

        async function typeText(characters, container) {
            for (let i = 0; i < characters.length; i++) {
                let currentMessage = characters.slice(0, i + 1).join('');
                $(container).html(currentMessage);
                await new Promise(resolve => setTimeout(resolve, 30));
            }
            $(container).append('<span class="msg_time">' + str_time + '</span>');
        }
    
        startSession().then(response => {
            let session_uuid = response.uuid;
            let message = rawText;
            sendMessage(message, session_uuid).then(async risposta => {
                let characters = risposta.split('');
                
                const date = new Date();
                const hour = date.getHours();
                const minute = date.getMinutes();
                const str_time = hour+":"+minute;

                let $botMessageContainer = $('<div class="d-flex justify-content-start mb-4"></div>');
                let $botImage = $('<div class="img_cont_msg"><div class="' + (invertTheme ? "rounded-circle user_img_msg" : "rounded-circle user_img_msg dark") + '" style="display:flex; align-items:center; justify-content:center;"><i class="fas fa-headphones"></i></div></div>');
                let $botMessage = $('<div class="' + (invertTheme ? "msg_cotainer" : "msg_cotainer dark") + '"></div>');
        
                $botMessageContainer.append($botImage);
                $botMessageContainer.append($botMessage);
        
                $("#messageFormeight").append($botMessageContainer);
        
                await typeText(characters, $botMessage);
            });
        });
        
    
        $("#messageArea").off("submit");
    }   
    
    const [invertTheme, setInvertTheme] = useState(false)

    //per invertire i colori in base al tema selezionato
    useEffect(() => {
        const body = document.body;
        const main = document.querySelector('.main-container');
        const powered = document.querySelector('.powered');
        const navbarLi = document.querySelectorAll('.navbar-container .navbar-list li ');
        const navbarBG = document.querySelector('.navbar-container');
        const label = document.querySelector('.display-mode-container');
        const img = document.querySelector('.thinkgood');
        const card = document.querySelector('.card');

        const chatInput = document.querySelector('.chatbot-input-container');
        const chatInputText = document.querySelector('.chatbot-input');
        const msgBot= document.querySelectorAll('.msg_cotainer', '.msg_cotainer.dark');
        const timeBot = document.querySelectorAll('.msg_time');
        const msgUser = document.querySelectorAll('.msg_cotainer_send, .msg_cotainer_send.dark');
        const timeUser = document.querySelectorAll('.msg_time_send');
        const imgUser = document.querySelectorAll('.user_img_msg, .user_img_msg.dark');

        const homeContainer = document.querySelector('.home-container');
        const ChiSiamoContainer = document.querySelector('.chi-siamo-container');
        const chiSiamo = document.querySelector('.chi-siamo-text');
        const founder = document.querySelectorAll('.founder-text');
        const member  = document.querySelectorAll('.member');
        const desc = document.querySelectorAll('.desc');
        const memberH2 = document.querySelectorAll('.member h2');
        const OzStudioContainer = document.querySelectorAll('.oz-studio-container');
        const OzStudio = document.querySelectorAll('.oz-text');
        const ContactContainer = document.querySelector('.contact-us-container');
        const ContactText = document.querySelector('.contact-text');
        const formText = document.querySelectorAll('.form-cointrol');
        const Border1 = document.querySelectorAll('.contact__form .form-control');
        const Border2 = document.querySelectorAll('.contact__form .form-control-l');



        const scrollBar = document.querySelector('::-webkit-scrollbar-thumb');

        if (invertTheme) {
            setTimeout(() => {
                if(scrollBar){
                    scrollBar.style.backgroundColor = 'white';
                }            
                body.style.backgroundColor = 'white';    
                main.style.backgroundColor = 'white';
                navbarBG.style.backgroundColor = `rgba(221,221,221,1)`;
                if (menu || nav) {
                    setTimeout(() => {
                        const navbarBGColor = getComputedStyle(navbarBG).backgroundColor;
                        navbarBG.style.backgroundColor = navbarBGColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                        
                        navbarLi.forEach(li => {
                            const liColor = getComputedStyle(li).color;
                            li.style.color = liColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                            
                            li.addEventListener('mouseover', function() {
                                const liHoverColor = getComputedStyle(li).color;
                                li.style.color = liHoverColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.5)');
                            });
            
                            li.addEventListener('mouseout', function() {
                                const liMouseOutColor = getComputedStyle(li).color;
                                li.style.color = liMouseOutColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                            });
                        });
                    }, 100);
                }

                navbarLi.forEach(li => {
                    li.style.color = 'black';
                    li.addEventListener('mouseover', function() {
                        li.style.color = 'rgba(1,1,1,0.7)';
                    });
                    
                    li.addEventListener('mouseout', function() {
                        li.style.color = 'black';
                    });
                });
                img.classList.add('invert');
                label.classList.add('invert');
                label.style.filter = 'invert(100%)';
                img.style.filter = 'invert(100%)';

                card.style.backgroundColor = '#dddddd';
                chatInput.style.backgroundColor = '#dddddd';
                chatInputText.style.backgroundColor = '#dddddd';
                chatInputText.style.color = 'black';
                if(msgUser) {
                    msgUser.forEach(x => {
                        x.style.color = 'black';
                    });
                    imgUser.forEach(x => {
                        x.style.color = 'black';
                        x.style.border = ' 0.5px solid black'
                    });
                    timeUser.forEach(x => {
                        x.style.color = 'black';
                    });
                }
                if(msgBot) {
                    msgBot.forEach(x => {
                        x.style.color = 'black';
                    });
                    timeBot.forEach(x => {
                        x.style.color = 'black';
                    });
                }
                
                if(homeContainer) {
                    powered.style.color = 'rgba(1,1,1,0.7)';
                    homeContainer.style.backgroundColor = 'white';
                    ChiSiamoContainer.style.backgroundColor = 'white';
                    chiSiamo.style.color = 'black';
                    member.forEach(m => {
                        m.style.backgroundColor = '#dddddd';
                    });
                    founder.forEach(f => {
                        f.style.color = 'rgba(1,1,1,0.8)';
                    })
                    desc.forEach(m => {
                        m.style.color = 'rgba(1,1,1,0.8)';
                    });
                    memberH2.forEach(m => {
                        m.style.color = 'black';
                    });
                    OzStudioContainer.forEach(o => {
                        o.style.backgroundColor = 'white';

                    });
                    OzStudio.forEach(o => {
                        o.style.color = 'black';

                    }); 
                    ContactContainer.style.backgroundColor = 'white';
                    ContactText.style.color = 'black';
                    Border1.forEach(b=> {
                        b.style.border = '1px solid #222222';
                    });
                    Border2.forEach(b=> {
                        b.style.border = '1px solid #222222';
                    });

                }
                
            }, 100);
        } else {
            setTimeout(() => {
                if(scrollBar){
                    scrollBar.style.backgroundColor = 'black';
                }
                body.style.backgroundColor = 'black';    
                main.style.backgroundColor = 'black';  
                navbarBG.style.backgroundColor = `rgba(34,34,34,1)`;
                if (menu) {
                    setTimeout(() => {
                        const navbarBGColor = getComputedStyle(navbarBG).backgroundColor;
                        navbarBG.style.backgroundColor = navbarBGColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                        
                        navbarLi.forEach(li => {
                            const liColor = getComputedStyle(li).color;
                            li.style.color = liColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                            
                            li.addEventListener('mouseover', function() {
                                const liHoverColor = getComputedStyle(li).color;
                                li.style.color = liHoverColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.5)');
                            });
            
                            li.addEventListener('mouseout', function() {
                                const liMouseOutColor = getComputedStyle(li).color;
                                li.style.color = liMouseOutColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                            });
                        });
                    }, 100);
                }
                navbarLi.forEach(li => {
                    li.style.color = 'white';
                    li.addEventListener('mouseover', function() {
                        li.style.color = 'rgba(255,255,255,0.7)';
                    });
                    
                    li.addEventListener('mouseout', function() {
                        li.style.color = 'white';
                    });
                });
                label.style.filter = 'invert(0%)';
                img.style.filter = 'invert(0%)';

                card.style.backgroundColor = '#222222';
                chatInput.style.backgroundColor = '#222222';
                chatInputText.style.backgroundColor = '#222222';
                chatInputText.style.color = 'white';
                if(msgUser) {
                    msgUser.forEach(x => {
                        x.style.color = 'white';
                    });
                    imgUser.forEach(x => {
                        x.style.color = 'white';
                        x.style.border = ' 0.5px solid white'
                    });
                    timeUser.forEach(x => {
                        x.style.color = 'white';
                    });
                }
                if(msgBot) {
                    msgBot.forEach(x => {
                        x.style.color = 'white';
                    });
                    timeBot.forEach(x => {
                        x.style.color = 'white';
                    });
                }
                if(homeContainer) {
                    powered.style.color = 'rgba(255,255,255,0.7)';
                    homeContainer.style.backgroundColor = 'black';
                    ChiSiamoContainer.style.backgroundColor = 'black';
                    chiSiamo.style.color = 'white';
                    member.forEach(m => {
                        m.style.backgroundColor = '#222222';
                    });
                    founder.forEach(f => {
                        f.style.color = 'rgba(255,255,255,0.8)';
                    })
                    desc.forEach(m => {
                        m.style.color = 'rgba(255,255,255,0.8)';
                    });
                    memberH2.forEach(m => {
                        m.style.color = 'white';
                    });
                    OzStudioContainer.forEach(o => {
                        o.style.backgroundColor = 'black';

                    });     
                    OzStudio.forEach(o => {
                        o.style.color = 'white';

                    });                
                    ContactContainer.style.backgroundColor = 'black';
                    ContactText.style.color = 'white';
                    Border1.forEach(b=> {
                        b.style.border = '1px solid white';
                    });
                    Border2.forEach(b=> {
                        b.style.border = '1px solid white';
                    });
                }            
            }, 100);
        }
    }, [invertTheme]);

    //cambiare il tema
    function handleThemeChange() {
        setInvertTheme(!invertTheme);
    }

    const [menu, setMenu] = useState(false)

    //andare nella sezione cliccata
    const moveTo = (id) => {
        setMenu(true);
        setTimeout(() => {
            const section = document.getElementById(id);
            if (section) {
                const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: sectionPosition,
                    behavior: 'smooth'
                });
            }
        }, 150);
    }

    //una volta cliccata una sezione, la navbar diventa più trasparente
    useEffect(() => {
        const navbarBG = document.querySelector('.navbar-container');
        const navbarLi = document.querySelectorAll('.navbar-container .navbar-list li');
    
        if (menu) {
            setTimeout(() => {
                const navbarBGColor = getComputedStyle(navbarBG).backgroundColor;
                navbarBG.style.backgroundColor = navbarBGColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                
                navbarLi.forEach(li => {
                    const liColor = getComputedStyle(li).color;
                    li.style.color = liColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                    
                    li.addEventListener('mouseover', function() {
                        const liHoverColor = getComputedStyle(li).color;
                        li.style.color = liHoverColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.5)');
                    });
    
                    li.addEventListener('mouseout', function() {
                        const liMouseOutColor = getComputedStyle(li).color;
                        li.style.color = liMouseOutColor.replace(/rgba?\((\d+), (\d+), (\d+)(, \d+(\.\d+)?)?\)/, 'rgba($1, $2, $3, 0.8)');
                    });
                });
            }, 100);
        }
    }, [menu]);

    const members = [
        { name: 'Giuseppe Anselmo', role: 'FAO', image: prova1, desc: 'FAO' },
        { name: "Lorenzo D'ercole", role: 'A&R', image: prova1, desc: 'A&R' },
        { name: 'Gregorio Carlino', role: 'Sound Engineer', image: prova1, desc: 'Sound Engineer' },
        { name: 'Alessio Cristello', role: 'Studio Manager', image: prova1, desc: 'Studio Manager' },
        { name: 'Simone (cognome)', role: 'Grafico', image: prova1, desc: 'Grafico' },
        { name: 'Rebecca Montanari', role: 'Publishing', image: prova1, desc: 'Publishing' },
        { name: 'Valentina Pirobano', role: 'Social Media Manager', image: prova1, desc: 'Social Media Manager' },
    ];

  return (
    <div className='main-container' id='main'>
        <p className='n_null' style={{display: 'none'}} />
        <div className={toBottom ?'navbar-container bars' : 'navbar-container'}>
            <ul className='navbar-list'>
                <li onClick={() => moveTo('chi-siamo')}>Chi Siamo</li>
                <li onClick={() => moveTo('40oz-studio')}>40oz Studio</li>
                <li onClick={() => moveTo('eventi')}>Eventi</li>
                <li onClick={() => moveTo('contatti')}>Contatti</li>
            </ul>
        </div>
       
        <div className={toBottom ? 'center-block beetween' : 'center-block'}>
            <img className={toBottom ? 'thinkgood beetween' : 'thinkgood'} src={logo} alt='Thinkgood' />
            <div className={toBottom ? 'chat-container view' : 'chat-container'}>
                <div className="card">
                    <div id="messageFormeight" className="card-body msg_card_body">

                        
                    </div>
                </div>  
            </div>
            <div className='chatbot-input-container'>
                <form id="messageArea" className="form-group" 
                onSubmit={activateBot}>
                    <input type='text' id="text" name="msg" className={invertTheme ? 'chatbot-input dark' : 'chatbot-input'} placeholder='Raccontaci il tuo progetto...' autoComplete='off' required/>
                    <button type="submit" id="send" className='chatbot-start'>Invia</button>
                </form>
            </div>
        </div>

        <div className={toBottom ? "display-mode-container" : "display-mode-container nav"}>
            <input className='input-mode' type='checkbox' id='dark-toggle'onChange={handleThemeChange} checked={invertTheme}/>
            <label className='display-mode' htmlFor='dark-toggle'>
                <img className="moon" src={moon} alt='' />
                <img className="sun" src={sun} alt='' />
            </label>
        </div>

        <div className={menu ? 'home-container' : 'home-container none'}>
            <div className='chi-siamo-container' id='chi-siamo'>
                <p className='chi-siamo-text'>Chi Siamo</p>
                <div className='desc-oz'>
                    <p className='desc'>Thinkgood è una label discografica con sede a Milano, specializzata nella creazione di composizioni
                                        musicali, sound design e jingle personalizzati per progetti innovativi.
                                        Fondata ufficialmente nel 2018, la nostra missione è intrecciare l'arte con le esigenze commerciali,
                                        offrendo soluzioni audio su misura per cinema, podcast, audiolibri, spot pubblicitari e brand di
                                        prestigio.
                                        <br></br><br></br>
                                        Ci distinguiamo per il nostro “approccio artigianale”, creando ogni suono da zero per far emergere
                                        al meglio i punti di forza di ogni progetto. La nostra attenzione ai dettagli ci permette di
                                        confezionare esperienze sonore uniche, capaci di elogiare il prodotto e lasciare un segno indelebile
                                        nell'immaginario del pubblico.
                                        <br></br><br></br>
                                        Oltre a una produzione sonora di qualità, Thinkgood offre una selezione esclusiva di artisti, tra cui
                                        cantanti e doppiatori professionisti, disponibili per collaborazioni esclusive. Possiamo scegliere una
                                        vasta gamma di talenti, che possono diventare la voce esclusiva di progetti sia a livello di cantato
                                        che di parlato, garantendo un tocco distintivo e su misura per ogni esigenza.<br></br>
                                        Collaboriamo con voci professionali, inclusi doppiatori di alto livello, per conferire profondità e
                                        autenticità a ogni progetto, sempre mantenendo uno standard di eccellenza. Thinkgood è la scelta di
                                        chi cerca soluzioni premium nel mondo del suono, in grado di trasmettere emozioni e potenziare
                                        l'identità del brand con un tocco di esclusività.</p>
                                                        </div>
                <p className='founder-text' font-size="40px !important">Founder</p>
                <div className='team-container'>
                    <div className='member'>
                        <div className='image-content'>
                            <span className='overlay'></span>
                        
                            <div className='member-image'>
                                <img src={prova1} alt='prova' className='member-img'/>
                            </div>
                        </div>
                        <div className='member-content'>
                            <h2 className='name'>Tony Pensabene</h2>
                            <p className='desc'>CEO</p>
                        </div>
                        <div class="icons">
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                            <a href="#">
                                <i class="fab fa-github"></i>
                            </a>
                            <a href="#">
                                <i class="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>

                    <div className='member'>
                        <div className='image-content'>
                            <span className='overlay'></span>
                        
                            <div className='member-image'>
                                <img src={prova1} alt='prova' className='member-img'/>
                            </div>
                        </div>
                        <div className='member-content'>
                            <h2 className='name'>Alessio Chiapino</h2>
                            <p className='desc'>Em</p>
                        </div>
                        <div class="icons">
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                            <a href="#">
                                <i class="fab fa-github"></i>
                            </a>
                            <a href="#">
                                <i class="fas fa-envelope"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <p className='founder-text' font-size="40px !important">Team</p>

                <Swiper
                modules={[EffectCoverflow, Pagination, Navigation]}
                initialSlide={2}
                slidesPerView={1}
                grabCursor={true}
                centeredSlides={true}
                navigation={true}
                pagination={true}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        effect: 'slide', // Usa un effetto di scorrimento semplice su schermi piccoli
                    },
                    768: {
                        slidesPerView: 2,
                        effect: 'coverflow', // Usa l'effetto coverflow su schermi medi
                    },
                    1024: {
                        slidesPerView: 3,
                        effect: 'coverflow',
                    },
                    1440: {
                        slidesPerView: 4,
                        effect: 'coverflow',
                    },
                }}
                effect={'coverflow'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                    scale: 0.85,
                }}
                className="custom-swiper"
                
            >

                {members.map((member, index) => (
                    <SwiperSlide key={index}>
                        <div className='member'>
                            <div className='image-content'>
                                <span className='overlay'></span>
                                <div className='member-image'>
                                    <img src={member.image} alt={member.name} className='member-img'/>
                                </div>
                            </div>
                            <div className='member-content'>
                                <h2 className='name'>{member.name}</h2>
                                <p className='desc'>{member.role}</p>
                            </div>
                            <div className="icons">
                                <a href="#">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="#">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                                <a href="#">
                                    <i className="fab fa-github"></i>
                                </a>
                                <a href="#">
                                    <i className="fas fa-envelope"></i>
                                </a>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper> 
            </div>

            <div className='oz-studio-container' id='40oz-studio'>
                <p className='oz-text'>40oz Studio</p>
                <div className='desc-oz'>
                    <p className='desc'> 40oz Studio, con sede a Milano, è il luogo dove sono nati molti dei successi dell’ambito rap. Con
                                        una forte esperienza nella produzione musicale, lo studio si distingue per la sua capacità di spaziare
                                        tra diversi generi, tra cui Rap, RnB, Pop, Elettronica e Rock, offrendo una versatilità unica nel
                                        panorama musicale.<br></br>
                                        All'interno del 40oz si trova un team dedicato di professionisti, ciascuno specializzato in un aspetto
                                        specifico: dalla fonia, alla produzione, alla direzione musicale. Questo approccio mirato ci consente
                                        di ottimizzare i tempi senza mai sacrificare la qualità del risultato.<br/>
                                        Il nostro obiettivo non è solo creare musica, ma far sì che ogni progetto funzioni a livello numerico
                                        e di successo commerciale. Al 40oz, creiamo pacchetti personalizzati, focalizzandoci sul progetto e
                                        non semplicemente sul prodotto. Puntiamo a rendere sostenibile il percorso artistico, assicurandoci
                                        che il risultato sia vincente sul mercato, perché per noi, un progetto deve funzionare prima ancora di
                                        essere solo bello.</p>
                </div>

                <div className="carousel-oz">
                    <Swiper
                        className="custom-swiper"
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        initialSlide={1}
                        slidesPerView={1} // Mostra una sola immagine alla volta per iniziare
                        grabCursor={true}
                        centeredSlides={true}
                        navigation={true}
                        pagination={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1, // Mostra una sola immagine alla volta su schermi piccoli
                                effect: 'slide', // Usa un effetto di scorrimento semplice su schermi piccoli
                            },
                            768: {
                                slidesPerView: 2,
                                effect: 'coverflow', // Usa l'effetto coverflow su schermi medi
                            },
                            1024: {
                                slidesPerView: 3,
                                effect: 'coverflow',
                            },
                            1440: {
                                slidesPerView: 4,
                                effect: 'coverflow',
                            },
                        }}
                        effect={'coverflow'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                            scale: 0.85,
                        }}
                    >
                        <SwiperSlide><img src={studio1} alt="Prima" className="member-img"/></SwiperSlide>
                        <SwiperSlide><img src={studio2} alt="Terza" className="member-img"/></SwiperSlide>
                        <SwiperSlide><img src={studio3} alt="Quarta" className="member-img"/></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className='oz-studio-container' id='eventi'>
                <p className='oz-text'>Eventi</p>
                <div className='desc-oz'>
                    <p className='desc'> Thinkgood si occupa di organizzare eventi live, portando sul palco artisti e DJ selezionati per offrire
                                        esperienze musicali uniche. Creiamo feste a tema e format esclusivi, capaci di trasformare ogni
                                        occasione in un evento indimenticabile.
                                        Abbiamo già organizzato eventi per artisti come Axos e Dani Faiv, fino ai listening party dedicati
                                        agli artisti per festeggiare l'uscita dei loro singoli a mezzanotte.<br></br>
                                        Grazie alla nostra vasta rete di
                                        contatti, possiamo coinvolgere talenti dello spettacolo, rendendo ogni evento ancora più speciale.
                                        Il nostro obiettivo è creare un'atmosfera cool, dove il divertimento dei partecipanti è al centro, il
                                        tutto in un contesto curato nei minimi dettagli e riservato a un pubblico esigente. Thinkgood
                                        garantisce un ambiente esclusivo, dove ogni aspetto è pensato per sorprendere e lasciare il segno.</p>
                </div>

                <div className="carousel-oz">
                    <Swiper
                        className="custom-swiper"
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        initialSlide={1}
                        slidesPerView={1} // Mostra una sola immagine alla volta per iniziare
                        grabCursor={true}
                        centeredSlides={true}
                        navigation={true}
                        pagination={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1, // Mostra una sola immagine alla volta su schermi piccoli
                                effect: 'slide', // Usa un effetto di scorrimento semplice su schermi piccoli
                            },
                            768: {
                                slidesPerView: 2,
                                effect: 'coverflow', // Usa l'effetto coverflow su schermi medi
                            },
                            1024: {
                                slidesPerView: 3,
                                effect: 'coverflow',
                            },
                            1440: {
                                slidesPerView: 4,
                                effect: 'coverflow',
                            },
                        }}
                        effect={'coverflow'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                            scale: 0.85,
                        }}
                    >
                        <SwiperSlide><img src={studio1} alt="Prima"/></SwiperSlide>
                        <SwiperSlide><img src={studio2} alt="Terza"/></SwiperSlide>
                        <SwiperSlide><img src={studio3} alt="Quarta"/></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className='contact-us-container' id='contatti'>
                <p className='contact-text'>Contattaci</p>
                <div className='map-form-container'>
                    <div className="map-container">
                        <iframe border-radius="15px" width="80%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Via%20Lanzone%2025%2020123%20Milano,%20Lombardia+(Thinkgood)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.gps.ie/">gps devices</a>
                        </iframe>
                    </div>
                    <div className='form-container'>
                        <form  className="contact__form w-100">
                            <div className='row-input'>
                                <input
                                    className="form-control-l"
                                    id="name"
                                    name="name"
                                    placeholder="Nome" 
                                    type="text"
                                    required 
                                />
                                <input
                                    className="form-control-l"
                                    id="name"
                                    name="name"
                                    placeholder="Cognome" 
                                    type="text"
                                    required 
                                />
                            </div>
                            <input
                                className="form-control rounded-0"
                                id="email"
                                name="email"
                                placeholder="Email"
                                type="email" 
                                required 
                            />
                            <textarea
                                className="form-control rounded-0"
                                id="message"
                                name="message"
                                placeholder="Messaggio"
                                rows="5" 
                                required
                            ></textarea>
                            <br />
                            <button className="submit-button" type="submit"> 
                                Invia
                            </button>
                        </form>
                    </div>
                </div>
                <hr className='custom-divider'></hr>
                <div style={{ display: 'flex', flexDirection:'column', marginBottom:20, gap:10}}>
                    <p className='mini-footer-text'style={{marginBottom: 0}}>Thinkgood Srls - Via Lanzone 25 Milano 20123 - P.IVA 12414860960</p>
                    <div className='contact-box'>
                        <FontAwesomeIcon className="footer-icon" icon={faPhone} />
                        <a className='mini-footer-text-a' style={{marginRight: 20}}  href="tel:3472988931">+39 3472988931</a>
                        <FontAwesomeIcon className="footer-icon" icon={faEnvelope} />
                        <a className='mini-footer-text-a' style={{marginRight: 20}} href="mailto:amministrazione@thinkgoodmusic.com">amministrazione@thinkgoodmusic.com</a>
                        <FontAwesomeIcon className="footer-icon" icon={faShieldAlt} />
                        <a className='mini-footer-text-a' style={{marginRight: 20}} href="mailto:thinkgood@pec.it" >thinkgood@pec.it</a>
                    </div>
        
                    <a className='mini-footer-text powered'style={{marginBottom: 0}} href="https://www.hi-dev.it/" target='_blank'>Powered by Hidev</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Main
